import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class Footer extends Component {
    render() {
        return (
          <footer className="page-footer grey darken-3">
            <div className="container">
              <div className="row">
                <div className="col s12 l8">
                  <h5 className="white-text">Kontakt</h5>
                  <p className="grey-text lighten-4">Wir freuen uns auf Ihre Anfrage!</p>
                </div>

                <div className="col s12 l4 right-align hide-on-small-only">
                  <h5 className="white-text">Links</h5>
                  <ul>
                    <li className="grey-text text-lighten-3"><Link to={"/datenschutz"}><span className="grey-text text-lighten-3">Datenschutz</span></Link></li>
                    <li className="grey-text text-lighten-3"><Link to={"/impressum"}><span className="grey-text text-lighten-3">Impressum</span></Link></li>
                    <li className="grey-text text-lighten-3"><a href="javascript:UC_UI.showSecondLayer();" className="grey-text text-lighten-3">Cookies</a></li>
                  </ul>
                </div>

                <div className="col s12 l4 center-align show-on-small hide-on-med-and-up">
                  <h5 className="white-text">Links</h5>
                  <ul>
                    <li className="grey-text text-lighten-3"><Link to={"/datenschutz"}><span className="grey-text text-lighten-3">Datenschutz</span></Link></li>
                    <li className="grey-text text-lighten-3"><Link to={"/impressum"}><span className="grey-text text-lighten-3">Impressum</span></Link></li>
                    <li className="grey-text text-lighten-3"><a href="javascript:UC_UI.showSecondLayer();" className="grey-text text-lighten-3">Cookies</a></li>
                  </ul>
                </div>

              </div>
              <div className="footer-copyright">
                <div className="container">
                  <div className="row">
                    <div className="col s12 center-align">
                      <span>
                        © 2020-2025 Relatively Unique GmbH
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </footer>
        )
    }
}

export default Footer;
